import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../../utils/color";

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs";
import { EnLayout } from "../../../components/layout/enLayout";

const EnCategoryPage = () => {
  return (
    <EnLayout
      title="PRODUCT | LIME"
      jpUrl="/product/category/"
      enUrl="/en/product/category/"
    >
      <Breadcrumbs>
        <Link to="/en/">HOME</Link>
        <span>/</span>
        <Link to="/en/product/">PRODUCT</Link>
        <span>/</span>
        <Link to="/en/product/category/">CATEGORY</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>EN CATEGORY</p>
      </SSection>
    </EnLayout>
  );
};

export default EnCategoryPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
